import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";

// const getToken = () => localStorage.getItem("Myleads");

// // API headers including token
// const getAuthHeaders = () => ({
//   headers: {
//     Authorization: `Bearer ${getToken()}`,
//   },
// });
export const getAllProformaInvoice = createAsyncThunk(
  "proforma/getAllProformaInvoice",
  async () => {
    try {
      const response = await BaseUrl.get("proforma/");
      // console.log("response.data===>", response.data);
      return response.data.data;
    } catch (error) {
      alert(`${error.response.data.message}`);
      throw error;
    }
  }
);
export const getClientDropdata = createAsyncThunk(
  "client/getClientDropdata",
  async () => {
    try {
      const response = await BaseUrl.get(`customers/customerDroupDown`);
      // console.log("response.data", response.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  }
);
export const getCustomerSingledata = createAsyncThunk(
  "client/getCustomerSingledata",
  async (id) => {
    // console.log("id====>", id);
    try {
      const response = await BaseUrl.get(`customers/${id}`);

      // console.log("response.data", response.data.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  }
);
export const AddSingleItemdata = createAsyncThunk(
  "invoice/AddSingleItemdata",
  async (data) => {
    try {
      const response = await BaseUrl.post(`invoice-item`, data);

      // console.log("response.data", response.data.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  }
);
export const proformInvoiceDown = createAsyncThunk(
  "invoice/proformInvoiceDown",
  async (data) => {
    try {
      const response = await BaseUrl.post(`generate-pdf/proforma`, { proformaNo: data });
      // console.log("response.data", response.data.data);
      return response.data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  }
);
export const GetAllItemdata = createAsyncThunk(
  "invoice/GetAllItemdata",
  async () => {
    try {
      const response = await BaseUrl.get(`item-setup`);

      // console.log("response.data", response.data.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  }
);
export const GetItemDropdata = createAsyncThunk(
  "item/GetItemDropdata",
  async () => {
    try {
      const response = await BaseUrl.get(`/item-setup/itemsDropDown`);
      // console.log("response.data", response.data.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  }
);
export const getItemSingledata = createAsyncThunk(
  "item/getItemSingledata",
  async (id) => {
    // console.log("id====>", id);
    try {
      const response = await BaseUrl.get(`item-setup/${id}`);

      // console.log("response.data", response.data.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  }
);
export const createProformadata = createAsyncThunk(
  "proforma/createProformadata",
  async (data) => {
    try {
      const response = await BaseUrl.post(`proforma/`, data);
      // console.log("response.data", response.data.data);
      return response.data.data;
    } catch (error) {
      console.log("error.response.data", error.response.data);

      return error.response.data;
    }
  }
);
export const getSingleProformadata = createAsyncThunk(
  "proforma/getSingleProformadata",
  async (id) => {
    // console.log("id===>", id);
    try {
      const response = await BaseUrl.get(`proforma/${id}`);
      console.log("response.data===============>", response.data.data);
      return response.data.data;
    } catch (error) {
      // console.log("error.response.data", error.response.data);
      return error.response.data;
    }
  }
);
export const updateSinglePorformadata = createAsyncThunk(
  "proforma/updateSinglePorformadata",
  async (data) => {
    try {
      const response = await BaseUrl.patch(`proforma/`, data);
      console.log("response.data", response.data.data);
      return response.data.data;
    } catch (error) {
      // console.log("error.response.data", error.response.data);

      return error.response.data;
    }
  }
);
export const deleteInvoiceItemdata = createAsyncThunk(
  "invoice/deleteInvoiceItemdata",
  async (id) => {
    try {
      const response = await BaseUrl.delete(`invoice-item/${id}`);
      console.log("response.data", response.data);
      return response.data;
    } catch (error) {
      // console.log("error.response.data", error.response.data);

      return error.response.data;
    }
  }
);
export const proformaSlice = createSlice({
  name: "proforma",
  initialState: {
    proformaData: [],
    clientDropData: [],
    singleData: {},
    allitemdata: [],
    itemdropData: [],
    singleItemData: {},
    singleProformaData: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllProformaInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProformaInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.proformaData = action.payload;
      })
      .addCase(getAllProformaInvoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(getClientDropdata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClientDropdata.fulfilled, (state, action) => {
        state.loading = false;
        state.clientDropData = action.payload;
      })
      .addCase(getClientDropdata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(getCustomerSingledata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerSingledata.fulfilled, (state, action) => {
        state.loading = false;
        state.singleData = action.payload;
      })
      .addCase(getCustomerSingledata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(GetAllItemdata.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllItemdata.fulfilled, (state, action) => {
        state.loading = false;
        state.allitemdata = action.payload;
      })
      .addCase(GetAllItemdata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(GetItemDropdata.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetItemDropdata.fulfilled, (state, action) => {
        state.loading = false;
        state.itemdropData = action.payload;
      })
      .addCase(GetItemDropdata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(getItemSingledata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getItemSingledata.fulfilled, (state, action) => {
        state.loading = false;
        state.singleItemData = action.payload;
      })
      .addCase(getItemSingledata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(getSingleProformadata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleProformadata.fulfilled, (state, action) => {
        state.loading = false;
        state.singleProformaData = action.payload;
      })
      .addCase(getSingleProformadata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export const getAllProformaInvoiceData = (state) => state.proforma.proformaData;
export const getAllClientDropData = (state) => state.proforma.clientDropData;
export const getSingleCustomerData = (state) => state.proforma.singleData;
export const getAllItemData = (state) => state.proforma.allitemdata;
export const getAllItemDropData = (state) => state.proforma.itemdropData;
export const getSingleItemData = (state) => state.proforma.singleItemData;
export const getProformaUniqueData = (state) =>
  state.proforma.singleProformaData;
// export const loader = (state) => state.invoice.loading;
export default proformaSlice.reducer;
