import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";

// const getToken = () => localStorage.getItem("Myleads");

// // API headers including token
// const getAuthHeaders = () => ({
//   headers: {
//     Authorization: `Bearer ${getToken()}`,
//   },
// });
export const getAllInvoice = createAsyncThunk(
  "invoice/getAllInvoice",
  async () => {
    try {
      const response = await BaseUrl.get("invoice");
      // console.log("response.data===>", response.data);
      return response.data.data;
    } catch (error) {
      alert(`${error.response.data.message}`);
      throw error;
    }
  }
);
export const getClientDropdata = createAsyncThunk(
  "client/getClientDropdata",
  async () => {
    try {
      const response = await BaseUrl.get(`customers/customerDroupDown`);
      // console.log("response.data", response.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  }
);
export const getCustomerSingledata = createAsyncThunk(
  "client/getCustomerSingledata",
  async (id) => {
    // console.log("id====>", id);
    try {
      const response = await BaseUrl.get(`customers/${id}`);

      // console.log("response.data", response.data.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  }
);
export const AddSingleItemdata = createAsyncThunk(
  "invoice/AddSingleItemdata",
  async (data) => {
    try {
      const response = await BaseUrl.post(`invoice-item`, data);

      // console.log("response.data", response.data.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  }
);
export const invoiceDown = createAsyncThunk(
  "invoice/invoiceDown",
  async (data) => {
    try {
      const response = await BaseUrl.post(`generate-pdf/invoice`, { invoiceNo: data });
      // console.log("response.data", response.data.data);
      return response.data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  }
);
export const GetAllItemdata = createAsyncThunk(
  "invoice/GetAllItemdata",
  async () => {
    try {
      const response = await BaseUrl.get(`item-setup`);

      // console.log("response.data", response.data.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  }
);
export const GetItemDropdata = createAsyncThunk(
  "item/GetItemDropdata",
  async () => {
    try {
      const response = await BaseUrl.get(`/item-setup/itemsDropDown`);
      // console.log("response.data", response.data.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  }
);
export const getItemSingledata = createAsyncThunk(
  "item/getItemSingledata",
  async (id) => {
    // console.log("id====>", id);
    try {
      const response = await BaseUrl.get(`item-setup/${id}`);

      // console.log("response.data", response.data.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  }
);
export const createInvoincedata = createAsyncThunk(
  "invoice/createInvoincedata",
  async (data) => {
    try {
      const response = await BaseUrl.post(`invoice`, data);
      // console.log("response.data", response.data.data);
      return response.data.data;
    } catch (error) {
      alert(error.response.data.message);
      throw error;
    }
  }
);
export const getSingleInvoincedata = createAsyncThunk(
  "invoice/getSingleInvoincedata",
  async (id) => {
    console.log("id===>", id);
    try {
      const response = await BaseUrl.get(`invoice/${id}`);
      console.log("response.data===============>", response.data.data);
      return response.data.data;
    } catch (error) {
      console.log("error.response.data", error.response.data);
      return error.response.data;
    }
  }
);
export const updateSingleInvoincedata = createAsyncThunk(
  "invoice/updateSingleInvoincedata",
  async (data) => {
    try {
      const response = await BaseUrl.patch(`invoice`, data);
      console.log("response.data", response.data.data);
      return response.data.data;
    } catch (error) {
      // console.log("error.response.data", error.response.data);
      alert(error.response.data.message);
      return error.response.data;
    }
  }
);
export const deleteInvoiceItemdata = createAsyncThunk(
  "invoice/deleteInvoiceItemdata",
  async (id) => {
    try {
      const response = await BaseUrl.delete(`invoice-item/${id}`);
      console.log("response.data", response.data);
      return response.data;
    } catch (error) {
      // console.log("error.response.data", error.response.data);

      return error.response.data;
    }
  }
);
export const invoiceSlice = createSlice({
  name: "invoice",
  initialState: {
    invoiceData: [],
    clientDropData: [],
    singleData: {},
    allitemdata: [],
    itemdropData: [],
    singleItemData: {},
    singleInvoiceData: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.invoiceData = action.payload;
      })
      .addCase(getAllInvoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(getClientDropdata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClientDropdata.fulfilled, (state, action) => {
        state.loading = false;
        state.clientDropData = action.payload;
      })
      .addCase(getClientDropdata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(getCustomerSingledata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerSingledata.fulfilled, (state, action) => {
        state.loading = false;
        state.singleData = action.payload;
      })
      .addCase(getCustomerSingledata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(GetAllItemdata.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllItemdata.fulfilled, (state, action) => {
        state.loading = false;
        state.allitemdata = action.payload;
      })
      .addCase(GetAllItemdata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(GetItemDropdata.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetItemDropdata.fulfilled, (state, action) => {
        state.loading = false;
        state.itemdropData = action.payload;
      })
      .addCase(GetItemDropdata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(getItemSingledata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getItemSingledata.fulfilled, (state, action) => {
        state.loading = false;
        state.singleItemData = action.payload;
      })
      .addCase(getItemSingledata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(getSingleInvoincedata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleInvoincedata.fulfilled, (state, action) => {
        state.loading = false;
        state.singleInvoiceData = action.payload;
      })
      .addCase(getSingleInvoincedata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export const getAllInvoiceData = (state) => state.invoice.invoiceData;
export const getAllClientDropData = (state) => state.invoice.clientDropData;
export const getSingleCustomerData = (state) => state.invoice.singleData;
export const getAllItemData = (state) => state.invoice.allitemdata;
export const getAllItemDropData = (state) => state.invoice.itemdropData;
export const getSingleItemData = (state) => state.invoice.singleItemData;
export const getInvoiceSingleData = (state) => state.invoice.singleInvoiceData;
// export const loader = (state) => state.invoice.loading;
export default invoiceSlice.reducer;
