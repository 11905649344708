import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";
// Function to retrieve token from localStorage
const getToken = () => localStorage.getItem("Myleads");
// API headers including token
const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});
export const subscriptionAPI = createAsyncThunk(
  "create/subscriptionAPI",
  async (id) => {
    try {
      const { data } = await BaseUrl.get(
        `customersub/subscription-status/${id}`,
        getAuthHeaders()
      );
      console.log("subscription data", data);
      return data.data;
    } catch (error) {
      alert(`${error.response.data.message}`);
      throw error;
    }
  }
);
export const singleSubscriptionAPI = createAsyncThunk(
  "create/singleSubscriptionAPI",
  async (id) => {
    try {
      const { data } = await BaseUrl.get(`customersub/${id}`, getAuthHeaders());
      console.log("subscription by id data", data);
      return data.data;
    } catch (error) {
      alert(`${error.response.data.message}`);
      throw error;
    }
  }
);
export const updateSubscriptionAPI = createAsyncThunk(
  "create/updateSubscriptionAPI",
  async (subdata) => {
    try {
      const { data } = await BaseUrl.patch(
        `customersub`,
        subdata,
        getAuthHeaders()
      );
      console.log("updated subscription data", data);
      return data.data;
    } catch (error) {
      alert(`${error.response.data.message}`);
      throw error;
    }
  }
);

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    companyDetails: {},
    subscription: [],
    singleSubscriptionById: {},
    paymentDetails: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(subscriptionAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(subscriptionAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.companyDetails = action.payload.company;
        state.subscription = action.payload.customer_subscriptions;
        state.paymentDetails = action.payload.customer_payments;
      })
      .addCase(subscriptionAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(singleSubscriptionAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(singleSubscriptionAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.singleSubscriptionById = action.payload;
      })
      .addCase(singleSubscriptionAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export const companyDetailsData = (state) => state.subscription.companyDetails;
export const subscriptionsData = (state) => state.subscription.subscription;
export const singleSubscriptionByIdData = (state) =>
  state.subscription.singleSubscriptionById;
export const paymentDetailsData = (state) => state.subscription.paymentDetails;

export default subscriptionSlice.reducer;
