import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";

export const companyAPI = createAsyncThunk(
  "create/companyAPI",
  async (userData) => {
    try {
      const { data } = await BaseUrl.post("company/ocp", userData);
      console.log("company data", data);
      return data.data;
    } catch (error) {
      alert(`${error.response.data.message}`);
      throw error;
    }
  }
);

export const companySlice = createSlice({
  name: "company",
  initialState: {
    test: "",
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(companyAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(companyAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.test = action.payload;
      })
      .addCase(companyAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export const responseData = (state) => state.company.test;

export default companySlice.reducer;
