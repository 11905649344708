import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./reducerSlice/authSlice";
import companySlice from "./reducerSlice/company";
import subscriptionSclice from "./reducerSlice/subscriptionSclice";
import invoiceSlice from "./reducerSlice/invoicesSlice";
import quotationSlice from "./reducerSlice/quotationSlice";
import proformaSlice from "./reducerSlice/proformaSlice";
export const store = configureStore({
  reducer: {
    auth: authSlice,
    company: companySlice,
    subscription: subscriptionSclice,
    invoice: invoiceSlice,
    quotation: quotationSlice,
    proforma: proformaSlice,
  },
});
