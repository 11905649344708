import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";

// Function to retrieve token from localStorage
const getToken = () => localStorage.getItem("Myleads");

// API headers including token
const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

export const registrationAPI = createAsyncThunk(
  "signup/registrationAPI",
  async (userData) => {
    try {
      const { data } = await BaseUrl.post("auth/signup", userData);
      return data.data;
    } catch (error) {
      alert(`${error.response.data.message}`);
      throw error;
    }
  }
);
export const loginAPI = createAsyncThunk(
  "signup/loginAPI",
  async (userData) => {
    try {
      const { data } = await BaseUrl.post("auth/signIn", userData);
      // console.log("user data", data);
      return data.data;
    } catch (error) {
      alert(`${error.response.data.message}`);
      throw error;
    }
  }
);
export const getAllUserAPI = createAsyncThunk(
  "users/getAllUserAPI",
  async () => {
    try {
      const { data } = await BaseUrl.get("auth/myleads/user", getAuthHeaders());
      // console.log("user data", data);
      return data.data;
    } catch (error) {
      alert(`${error.response.data.message}`);
      throw error;
    }
  }
);

export const getAllUserBasedOnCompanyId = createAsyncThunk(
  "users/getAllUserBasedOnCompanyId",
  async (id) => {
    try {
      const { data } = await BaseUrl.get(
        `auth/UserListByCompID/${id}`,
        getAuthHeaders()
      );
      console.log("user data", data);
      return data.data;
    } catch (error) {
      alert(`${error.response.data.message}`);
      throw error;
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    users: [],
    unicCompanyUsers: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUserAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(getAllUserAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(getAllUserBasedOnCompanyId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUserBasedOnCompanyId.fulfilled, (state, action) => {
        state.loading = false;
        state.unicCompanyUsers = action.payload;
      })
      .addCase(getAllUserBasedOnCompanyId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export const userData = (state) => state.auth.users;
export const unicCompanyUserData = (state) => state.auth.unicCompanyUsers;
export const loader = (state) => state.auth.loading;

export default authSlice.reducer;
