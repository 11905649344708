import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { Suspense, lazy } from "react";
//navbar component
const SideBarComponent = lazy(() => import("./components/navbars/SideBar"));
const HeaderBarComponent = lazy(() => import("./components/navbars/HeaderBar"));
//authcomponent
const LoginFormComponent = lazy(() => import("./components/auth/LoginForm"));
const RegistrationComponent = lazy(() =>
  import("./components/auth/RegistrationForm")
);
//customer component
const ManageCustomerComponent = lazy(() =>
  import("./components/customer/ManageCustomer")
);
const SubscriptionStatusComponent = lazy(() =>
  import("./components/customer/SubscriptionStatus")
);
const InvoiceComponent = lazy(() =>
  import("./components/invoice/ManageInvoice")
);
const AddNewInvoiceComponent = lazy(() =>
  import("./components/invoice/AddNewInvoice")
);
const UpdateSubscriptionComponent = lazy(() =>
  import("./components/subscription/UpdateSubscription")
);
//Invoice component
const AddNewItemComponent = lazy(() => import("./components/invoice/AddItem"));
const EditItemComponent = lazy(() => import("./components/invoice/EditItem"));
const EditInvoiceComponent = lazy(() =>
  import("./components/invoice/EditInvoice")
);
const CopyInvoiceComponent = lazy(() =>
  import("./components/invoice/CopyInvoice")
);
const CustomItemComponent = lazy(() =>
  import("./components/invoice/CustomItem")
);
const NewInvoiceModalComponent = lazy(() =>
  import("./components/invoice/NewInvoiceModal")
);
//quptation component
const ManageQuotationComponent = lazy(() =>
  import("./components/quotationInvoice/ManageQuotationInvoice")
);
const AddQuotationComponent = lazy(() =>
  import("./components/quotationInvoice/AddQuotationInvoice")
);
const EditQuotationComponent = lazy(() =>
  import("./components/quotationInvoice/EditQuotationInvoice")
);
const CopyQuotationComponent = lazy(() =>
  import("./components/quotationInvoice/CopyQuotation")
);
const CopytoProformaComponent = lazy(() =>
  import("./components/quotationInvoice/CopyProforma")
);
const ConverttoInvoiceComponent = lazy(() =>
  import("./components/quotationInvoice/ConvertInvoice")
);
const NewQuotationModalComponent = lazy(() =>
  import("./components/quotationInvoice/NewQuotationNumberModal")
);
const AddNewQuotationItemComponent = lazy(() =>
  import("./components/quotationInvoice/AddQuotatiomItem")
);
const EditQuotationItemComponent = lazy(() =>
  import("./components/quotationInvoice/EditQuotationItem")
);
//proforma component
const ManageProformaComponent = lazy(() =>
  import("./components/manageProforma/ManageProformaInvoice")
);
const AddProformaComponent = lazy(() =>
  import("./components/manageProforma/AddProformaInvoice")
);
const EditProformaComponent = lazy(() =>
  import("./components/manageProforma/EditProformaInvoice")
);
const CopyProformaComponent = lazy(() =>
  import("./components/manageProforma/CopyProformaInvoice")
);
const CopyToInvoiceComponent = lazy(() =>
  import("./components/manageProforma/CopyToInvoice")
);
const AddProformaItemComponent = lazy(() =>
  import("./components/manageProforma/AddProformaItem")
);
const EditProformaItemComponent = lazy(() =>
  import("./components/manageProforma/EditProformaItem")
);
const CustomProformaItemComponent = lazy(() =>
  import("./components/manageProforma/CustomPerformaItem")
);
function App() {
  const location = useLocation();
  const isLoginPage =
    location.pathname === "/" || location.pathname === "/regitration";
  return (
    <>
      <div className="app">
        {!isLoginPage && (
          <Suspense fallback={<div>Loading...</div>}>
            <SideBarComponent />
          </Suspense>
        )}
        <main className="content">
          {!isLoginPage && (
            <Suspense fallback={<div>Loading...</div>}>
              <HeaderBarComponent />
            </Suspense>
          )}
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <LoginFormComponent />
                </Suspense>
              }
            />
            <Route
              path="/regitration"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <RegistrationComponent />
                </Suspense>
              }
            />
            <Route path="my-leads">
              <Route
                index
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <ManageCustomerComponent />
                  </Suspense>
                }
              />
              <Route
                path="subscription/:cid"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <SubscriptionStatusComponent />
                  </Suspense>
                }
              />
              <Route
                path="update-subscription/:sid"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <UpdateSubscriptionComponent />
                  </Suspense>
                }
              />
              <Route
                path="ManageInvoice"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <InvoiceComponent />
                  </Suspense>
                }
              />
              <Route
                path="AddNewInvoice"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <AddNewInvoiceComponent />
                  </Suspense>
                }
              />
              <Route
                path="AddItem"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <AddNewItemComponent />
                  </Suspense>
                }
              />
              <Route
                path="EditItem"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <EditItemComponent />
                  </Suspense>
                }
              />
              <Route
                path="CustomItem"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <CustomItemComponent />
                  </Suspense>
                }
              />
              <Route
                path="EditInvoice/:eid"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <EditInvoiceComponent />
                  </Suspense>
                }
              />
              <Route
                path="CopyInvoice/:eid"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <CopyInvoiceComponent />
                  </Suspense>
                }
              />
              <Route
                path="NewInvoiceModal"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <NewInvoiceModalComponent />
                  </Suspense>
                }
              />
              <Route
                path="ManageQuotationInvoice"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <ManageQuotationComponent />
                  </Suspense>
                }
              />
              <Route
                path="AddQuotationInvoice"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <AddQuotationComponent />
                  </Suspense>
                }
              />
              <Route
                path="EditQuotationInvoice/:qid"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <EditQuotationComponent />
                  </Suspense>
                }
              />
              <Route
                path="CopyQuotation/:eid"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <CopyQuotationComponent />
                  </Suspense>
                }
              />
              <Route
                path="CopyProforma/:eid"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <CopytoProformaComponent />
                  </Suspense>
                }
              />
              <Route
                path="ConvertInvoice/:eid"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <ConverttoInvoiceComponent />
                  </Suspense>
                }
              />
              <Route
                path="NewQuotationNumberModal"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <NewQuotationModalComponent />
                  </Suspense>
                }
              />
              <Route
                path="AddQuotationItem"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <AddNewQuotationItemComponent />
                  </Suspense>
                }
              />
              <Route
                path="EditQuotationItem"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <EditQuotationItemComponent />
                  </Suspense>
                }
              />

              <Route
                path="ManageProformaInvoice"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <ManageProformaComponent />
                  </Suspense>
                }
              />
              <Route
                path="AddProformaInvoice"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <AddProformaComponent />
                  </Suspense>
                }
              />
              <Route
                path="EditProformaInvoice/:eid"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <EditProformaComponent />
                  </Suspense>
                }
              />
              <Route
                path="CopyProformaInvoice/:eid"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <CopyProformaComponent />
                  </Suspense>
                }
              />
              <Route
                path="CopyToInvoice/:eid"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <CopyToInvoiceComponent />
                  </Suspense>
                }
              />
              <Route
                path="AddProformaItem"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <AddProformaItemComponent />
                  </Suspense>
                }
              />
              <Route
                path="EditProformaItem"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <EditProformaItemComponent />
                  </Suspense>
                }
              />
              <Route
                path="CustomProformaItem"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <CustomProformaItemComponent />
                  </Suspense>
                }
              />
            </Route>

            <Route path="*" element={<div>Page Not Found..</div>} />
          </Routes>
        </main>
      </div>
    </>
  );
}

export default App;
